import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import { Container, Row, Column } from '../ui/layout/Grid'
import { StoreCtx } from '../../store'
import { DataPricing, ApiPricing } from '../Products/pricing-list'
import { ContentBox } from '../ui/layout/ContentBox'
import { Selection } from './RegForm'
import { Tick } from '../ui/Icons'
import theme from '../../theme'
import { SectionHeading } from '../ui/Heading'
import Button from '../ui/forms/Buttons'
import { TopTitle } from '../Hero/PageIntro'
import { fetchData } from '../../utils'
import ErrorHandler from './ErrorHandler'

// Icons
import stripeIcon from '../../images/account/stripe.svg'
import bitcoinIcon from '../../images/account/bitcoin.svg'
import lock from '../../images/account/lock.svg'

const SelectedBox = styled(Selection)`
  border-color: ${ props => props.isActive ? props.theme.color.primaryColor : props.theme.color.grey[2] };
  margin: 0;
  position: relative;
  font-weight: 400;
  color: ${ props => props.isActive && props.theme.color.primaryColor };
  transition: all 0.25s ease;

  &::after {
    content: '';
    display: ${ props => !props.isActive ? 'block' : 'none' };
    position: absolute;
    right: 22px;
    top: 50%;
    width: 20px;
    height: 20px;
    border: 2px solid ${ props => props.theme.color.grey[2] };
    border-radius: 20px;
    box-sizing: border-box;
    transform: translateY(-50%);
    transition: all 0.25s ease;
  }

  &:hover {
    cursor: pointer;

    &::after {
      border-color: ${ props => props.theme.color.primaryColor };
    }
  }

  span {
    font-weight: 400;
  }

  svg {
    max-width: 100%;
    height: auto;
  }

  img {
    filter: ${ props => !props.isActive && 'grayscale(100%)' };
    max-width: 100px;
    opacity: ${ props => !props.isActive && 0.5 };
  }
`

const Total = styled.section`
  background-color: ${ props => props.theme.color.grey[0] };
  padding: 0.5em 0.8em;
  margin-top: 1.7em;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  strong {
    font-size: 2.7rem;
  }
`

const CustomerDetails = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-family: ${ props => props.theme.text.fontFamily };
    display: inline;
  }
`

const Error = styled.div`
  color: ${ props => props.theme.color.error };
  padding: 0.5em 0;
  text-align: right;
`

const Checkout = ({ location }) => {
  const { actions, data: { newAccount } } = useContext(StoreCtx)
  const [stripe, setStripe] = useState(null)
  const [stripeError, setStripeError] = useState({ message: null })
  const [status, setStatus] = useState(null)
  const [activePackage, setActivePackage] = useState()
  const [payMethod, setPayMethod] = useState('Stripe')

  const payGateways = [
    {
      name: 'Stripe',
      icon: stripeIcon,
    },
    {
      name: 'Crypto',
      icon: bitcoinIcon,
    },
  ]

  useEffect(() => {
    if (!newAccount.name) navigate('/account/register')

    if (!stripe) {
      setStripe(window.Stripe(process.env.GATSBY_STRIPE_KEY))
    }

    if (newAccount.selectedPackage.package) {
      setActivePackage(newAccount.selectedPackage.package)
    }
  }, [activePackage, payMethod])

  async function payNow () {
    const producType = newAccount.selectedPackage.product
    await fetchData(
      `${ process.env.GATSBY_ACCOUNT_SIGNUP }/${ producType }`, {
        method: 'POST',
        body: JSON.stringify({
          full_name: newAccount.name,
          email: newAccount.email,
          password: newAccount.password
        })
      })
      .then(res => {
        if (res.success) {
          return stripe.redirectToCheckout({
            items: [
              { sku: process.env.GATSBY_STRIPE_SKU, quantity: 1 }
            ],
            successUrl: `${ location.origin }/account/success`,
            cancelUrl: `${ location.origin }/account/success`,
            customerEmail: newAccount.email,
          }).then(res => {
            if (res.error.message) {
              setStatus(state => {
                return {
                  ...state,
                  message: res.error.message
                }
              })
            }
          }).catch(e => setStripeError(state => {
            return {
              ...state,
              message: e
            }
          })
          )
        }
        setStatus(state => {
          return {
            ...state,
            ...res
          }
        })
      })
  }

  function payWithCrypto () {
    navigate('/account/success')
  }

  function cancelOrder () {
    actions.setNewAccount({})
    actions.setPackageSelection({})
    navigate('/account/register')
  }

  function switchPackage (newSelect) {
    const newPackage = {
      ...newSelect.button.state,
      price: newSelect.price,
      period: newSelect.priceCondition
    }
    setActivePackage(newSelect.topTitle)
    actions.setPackageSelection(newPackage)
  }

  function switchPayment (name) {
    setPayMethod(name)
  }

  function renderPackages (obj) {
    if (!Array.isArray(obj) || !obj) return null
    return (
      <>
        {obj.map(el => {
          if (el.topTitle !== 'Enterprise') {
            return (
              <Column key={el.topTitle}>
                <SelectedBox
                  className="selected-box"
                  onClick={() => switchPackage(el)}
                  isActive={activePackage === el.topTitle}
                >
                  <div>
                    <span>
                      {el.topTitle} / <strong>{el.price}</strong>
                    </span>
                  </div>
                  {activePackage === el.topTitle &&
                    <Tick strokeColor={theme.color.primaryColor} />
                  }
                </SelectedBox>
              </Column>
            )
          }
        }
        )}
      </>
    )
  }

  return (
    <>
      <Container size="md">
        <Row mt="6em">
          <Column>
            <SectionHeading>
              <h1>You’re On Your Way To A Whole New World Of Crypto</h1>
              <p>Please select which product option you’d like to add to your account.</p>
            </SectionHeading>
          </Column>
        </Row>
        <Row
          mt="0"
          mb="0"
        >
          <Column>
            <h3>Data Packages</h3>
          </Column>
        </Row>
        <Row
          grid={1 / 2}
          gutter={0.2}
          mt="0"
        >
          {renderPackages(DataPricing)}
        </Row>
        <Row
          mt="0"
          mb="0"
        >
          <Column>
            <h3>API Packages</h3>
          </Column>
        </Row>
        <Row
          grid={1 / 2}
          gutter={0.2}
          mt="0"
        >
          {renderPackages(ApiPricing)}
        </Row>
      </Container>
      <Container size="md">
        <ContentBox>
          <h2>Payment Method</h2>
          <span>How would you like to pay?</span>
          <Row
            grid={1 / 2}
            gutter={0.5}
          >
            {payGateways.map((el, idx) =>
              <Column key={el.name}>
                <SelectedBox
                  className="selected-box"
                  isActive={el.name === payMethod}
                  onClick={() => switchPayment(el.name)}
                  css={'min-height:80px;'}
                >
                  <img
                    src={el.icon}
                    alt={`${ el.name } icon`}
                  />
                  {el.name === payMethod &&
                    <Tick strokeColor={theme.color.primaryColor} />
                  }
                </SelectedBox>
              </Column>
            )}
          </Row>
        </ContentBox>
      </Container>
      <Container size="md">
        <Row>
          <Column>
            <ContentBox>
              <h2>Account Details</h2>
              <CustomerDetails>
                <div>
                  <h3>{newAccount.name}{' '}</h3>
                  <span>({newAccount.email})</span>
                </div>
                <Button
                  as="button"
                  appearance="secondary"
                  sizing="sm"
                  onClick={() => cancelOrder()}
                >
                  Cancel
                </Button>
              </CustomerDetails>
              {payMethod === 'Stripe' &&
                <>
                  <Total>
                    {activePackage
                      ? <span>
                        <div>
                          <TopTitle>{newAccount.selectedPackage.package}</TopTitle>
                        </div>
                        <strong>{newAccount.selectedPackage.price}</strong>
                        <span>{' '}/{' '}{newAccount.selectedPackage.period}</span>
                      </span>
                      : <span>Select a product package</span>
                    }
                    {activePackage &&
                      <Button
                        as="button"
                        id="checkout-fiat"
                        appearance="primary"
                        sizing="md"
                        icon={lock}
                        onClick={() => payNow()}
                      >
                        Purchase
                      </Button>
                    }
                  </Total>
                  {stripeError.message &&
                    <Error>There was an error processing your request. Please try again later.</Error>
                  }
                </>
              }
              {payMethod === 'Crypto' &&
                <>
                  <Total>
                    {activePackage
                      ? <span>
                        <div>
                          <TopTitle>{newAccount.selectedPackage.package}</TopTitle>
                        </div>
                        <strong>{newAccount.selectedPackage.price}</strong>
                        <span>{' '}/{' '}{newAccount.selectedPackage.period}</span>
                      </span>
                      : <span>Select a product package</span>
                    }
                    <Button
                      as="button"
                      id="checkout-btc"
                      appearance="primary"
                      sizing="md"
                      icon={lock}
                      onClick={() => payWithCrypto()}
                    >
                      Purchase
                    </Button>
                  </Total>
                </>
              }
            </ContentBox>
          </Column>
        </Row>
        <ErrorHandler state={status} />
      </Container>
    </>
  )
}

export default Checkout
