import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Location, Router } from '@reach/router'

import SEO from '../../components/seo'
import { StoreCtx } from '../../store'
import { Wrapper, Container, Row, Column } from '../../components/ui/layout/Grid'
import RegForm from '../../components/Account/RegForm'
import Checkout from '../../components/Account/Checkout'
import Verify from '../../components/Account/Verify'
import theme from '../../theme'
import RegisterRoute from '../../components/Account/RegisterRoute'

const Register = ({ location }) => {
  const { actions } = useContext(StoreCtx)

  useEffect(() => {
    const selectedPackage = location.state
    if (selectedPackage) {
      actions.setPackageSelection(selectedPackage)
    }
  }, [])

  return (
    <>
      <SEO title="Account Registration" />
      <Wrapper bgColor={theme.color.grey[0]}>
        <Container>
          <Row
            mt="0"
            mb="0"
            justify="center"
            css={`
              & > div {
                width: 100%;
              }
            `}
          >
            <Location>
              {() =>
                <Router>
                  <RegisterRoute
                    path="/account/register"
                    component={RegForm}
                  />
                  <RegisterRoute
                    path="/account/register/checkout"
                    component={Checkout}
                  />
                  <RegisterRoute
                    path="/account/register/verify"
                    component={Verify}
                  />
                </Router>
              }
            </Location>
          </Row>
        </Container>
      </Wrapper>
    </>
  )
}

Register.propTypes = {
  location: PropTypes.object
}

export default Register
