import React, { useState, useEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import queryString from 'query-string'

import { Container, Row, Column } from '../ui/layout/Grid'
import { ContentBox } from '../ui/layout/ContentBox'
import { SectionHeading } from '../ui/Heading'
import Button from '../ui/forms/Buttons'
import { fetchData } from '../../utils'
import ErrorHandler from './ErrorHandler'
import { Cross, Tick, Loader } from '../ui/Icons'
import theme from '../../theme'

const Verify = ({ location }) => {
  const [status, setStatus] = useState(null)

  useEffect(() => {
    const token = queryString.parse(location.search).token
    if (!token) {
      navigate('/')
    }

    verifyAccount(token)
  }, [])

  async function verifyAccount (token) {
    await fetchData(
      `${ process.env.GATSBY_ACCOUNT_VERIFY }/${ token }`, {
        method: 'POST'
      })
      .then(res => {
        setStatus(state => {
          return {
            ...state,
            ...res
          }
        })
      })
  }

  return (
    <>
      <Container size="md">
        <Row
          mt="6em"
          css={`
            min-height: 65vh;
          `}
        >
          {!status &&
            <Column>
              <ContentBox
                css={`
                  display: flex;
                  justify-content: center;
                `}
              >
                <Loader bgColor={theme.color.primaryColor} />
              </ContentBox>
            </Column>
          }
          {status && !status.success &&
            <Column>
              <ContentBox
                css={`
                  text-align:center;
                  svg {
                    width: 100px;
                    height: 100px;
                  }
                `}
              >
                <SectionHeading>
                  <Cross />
                  <h1>Something went wrong :(</h1>
                </SectionHeading>
                <p>There was a problem verifying your email address.</p>
                <p>Contact us at hello@investabit.com and we&apos;ll jump in to help!</p>
              </ContentBox>
            </Column>
          }
          {status && status.success &&
            <Column>
              <ContentBox
                css={`
                text-align:center;
                svg {
                  width: 100px;
                  height: 100px;
                }
              `}
              >
                <SectionHeading>
                  <Tick />
                  <h1>Congratulations!</h1>
                </SectionHeading>
                <p>
                  You've sucessfully verified your email and can now continue to your account, complete your order or adjust your profile by using the link below.
                </p>
                <Button
                  as="a"
                  appearance="link"
                  to="/"
                  style={{ marginTop: '1.2em' }}
                >
                  Access Your Account
                </Button>
              </ContentBox>
            </Column>
          }
        </Row>
        <ErrorHandler state={status} />
      </Container>
    </>
  )
}

export default Verify
