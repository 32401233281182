import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Cross } from '../ui/Icons'

const ErrorBody = styled.div`
  position: absolute;
  bottom: 2em;
  right: 2em;
  width: 340px;
  padding: 2em;
  background-color: #fff;
  border-radius: ${ props => props.theme.radius.box };
  box-shadow: 0 6px 20px ${ props => props.theme.color.grey[1] };
  z-index: 1000;
  cursor: pointer;

  h4 {
    font-size: 2rem;
    margin: 0;
    color: ${ props => props.theme.color.error };

    svg {
      position: relative;
      top: 4px;
    }
  }

  p {
    margin-top: 0.5em;
    margin-bottom: 0;
    color: ${ props => props.theme.color.grey[4] };
  }
`

const ErrorHandler = ({ state }) => {
  const [active, setActive] = useState(true)

  return (
    state && active &&
      <ErrorBody onClick={() => setActive(false)}>
        <h4><Cross /> <span>There was a problem</span></h4>
        {state.errors.map(msg =>
          <p key={msg}>{msg}</p>
        )}
      </ErrorBody>
  )
}

ErrorHandler.propTypes = {
  state: PropTypes.shape({
    success: PropTypes.bool,
    code: PropTypes.number,
    status: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string)
  })
}

export default ErrorHandler
