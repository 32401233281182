import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import omit from 'lodash/omit'
import { Link, navigate } from 'gatsby'

import { StoreCtx } from '../../store'
import { Container, Row, Column } from '../ui/layout/Grid'
import { Input, FormGroup, Message, Label } from '../ui/forms/Input'
import Button from '../ui/forms/Buttons'
import { Tick } from '../ui/Icons'
import { TopTitle } from '../Hero/PageIntro'
import { isEmpty } from '../../utils'
import theme from '../../theme'
import { SectionHeading } from '../ui/Heading'

const Form = styled.form`
  margin-top: 2em;
`

const Selection = styled.div`
  background-color: #fff;
  border: 2px solid ${ props => props.theme.color.primaryColor };
  padding: 0.8em 1.2em;
  border-radius: ${ props => props.theme.radius.box };
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0;

  span {
    display: block;
    font-size: 1.8rem;
    font-weight: 700;
  }
`

const RegForm = () => {
  const { actions, data: { newAccount } } = useContext(StoreCtx)
  const [formError, setFormError] = useState({})
  const [account, setAccount] = useState({
    name: '',
    email: '',
    password: ''
  })
  // console.log(formError)
  const [isDisabled, setIsDisabled] = useState()

  useEffect(() => {
    setIsDisabled(isFormEmpty() || !isEmpty(formError))
  }, [account])

  const isFormEmpty = () => {
    // Iterate over the form state object and check if
    // the length of values are bigger than 0
    if (account) {
      const entriesLength = Object.values(account).map(el => el.length)
      return entriesLength.includes(0)
    }
  }

  const handleSubmition = async e => {
    e.preventDefault()
    if (isEmpty(formError)) {
      await actions.setNewAccount(account)
      navigate('/account/register/checkout')
    }
  }

  const handleInput = e => {
    const { name, value } = e.target
    const emailReg = /\S+@\S+\.\S+/

    // Check email validity
    if (name === 'email') {
      if (!formError.email && !emailReg.test(value)) {
        setFormError({
          ...formError,
          [name]: 'Provide a valid email address'
        })
      }

      if ((formError && formError.email) && emailReg.test(value)) {
        setFormError(
          omit(formError, 'email')
        )
      }
    }

    // Check password length
    if (name === 'password') {
      if (!formError.password && value.length < 8) {
        setFormError({
          ...formError,
          [name]: 'Password is not long enough'
        })
      }

      if (formError.password && value.length > 8) {
        setFormError(
          omit(formError, 'password')
        )
      }
    }

    setAccount({ ...account, [name]: value })
  }

  return (
    <Container size="sm">
      <Row
        dir="column"
        mt="6em"
        mb="6em"
      >
        <SectionHeading>
          <h1>Let&apos;s Get You Started</h1>
          <p>Access Investabit&apos;s tools by completing the form below. You’ll receive a welcome email with additional information as well.</p>
        </SectionHeading>
        {newAccount.selectedPackage.package &&
          <Selection>
            <div>
              <TopTitle>Selected Package</TopTitle>
              <span>{newAccount.selectedPackage.package} / {newAccount.selectedPackage.price} / {newAccount.selectedPackage.period}</span>
            </div>
            <Tick strokeColor={theme.color.primaryColor} />
          </Selection>
        }
        <Form
          onSubmit={e => e.preventDefault()}
          id="registration"
        >
          <FormGroup>
            <Label htmlFor="name">Full Name</Label>
            <Input
              type="text"
              name="name"
              placeholder="Your Name"
              onChange={e => handleInput(e)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              name="email"
              placeholder="Enter your email"
              onChange={e => handleInput(e)}
              required
              hasError={!!formError.email}
            />
            <Message>{formError && formError.email}</Message>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <Input
              type="password"
              name="password"
              placeholder="At least 8 characters long"
              onChange={e => handleInput(e)}
              required
              hasError={!!formError.password}
            />
            <Message>{formError && formError.password}</Message>
          </FormGroup>
          <p>By creating an Investabit account you agree to our <Link to="/terms-of-service/">Terms of Service </Link> and <Link to="/privacy-policy/">Privacy Policy</Link>.</p>
          <Button
            disabled={isDisabled}
            as="button"
            appearance={isDisabled ? 'disabled' : 'primary'}
            onClick={e => handleSubmition(e)}
            css={'margin-top: 0.5em;'}
          >
            Create Account
          </Button>
        </Form>
      </Row>
    </Container>
  )
}

export { RegForm as default, Selection }
