import theme from '../../theme'

const DataPricing = [
  {
    topBorder: theme.color.primaryColor,
    topTitle: 'Entire Dataset',
    price: '$200k',
    priceCondition: 'Onetime',
    description: 'Looking to seed your research or trading algorithm? If you want the entire dataset, we’ve simplified the pricing model',
    features: [
      'All Exchanges',
      'All Coins',
      'Order Books',
      'Snapshots',
      'Tick Data',
      'Trade Data',
      'OHLCV, VWAP, TWAP'
    ],
    button: {
      title: 'Get Started',
      to: '/account/register',
      appearance: 'primary',
      state: {
        product: 'data-sales',
        package: 'Entire Dataset'
      }
    }
  },
  {
    topBorder: theme.color.accentColor,
    topTitle: 'A La Carte',
    price: '$0.035 / MB',
    priceCondition: 'on Demand',
    description: 'Built for those who know exactly what they’re looking for. Our self service “A La Carte” model you only pay for the size of the data chosen',
    features: [
      'Individual Data Sets',
      'Select Date Ranges',
      'By Coin (BTC, LTC, ETH…)',
      'By Exchange',
      'Order Books',
      'Snapshots',
      'Tick Data',
      'Trade Data',
      'OHLCV, VWAP, TWAP'
    ],
    button: {
      title: 'Get Started',
      to: '/account/register',
      appearance: 'secondary',
      state: {
        product: 'data-sales',
        package: 'A La Carte'
      }
    }
  },
]

const ApiPricing = [
  {
    topBorder: theme.color.primary[0],
    topTitle: 'Hobbist',
    price: '$0',
    priceCondition: 'per Month',
    description: 'For testing or hobby projects of individuals',
    features: [
      'Attribution Required',
      'Not for Business Use',
      '100 Daily Requests',
      'HTTP - REST'
    ],
    button: {
      title: 'Get Started',
      to: '/account/register',
      appearance: 'secondary',
      state: {
        product: 'data-api',
        package: 'Hobbist'
      }
    }
  },
  {
    topBorder: theme.color.primary[1],
    topTitle: 'Startup',
    price: '$35',
    priceCondition: 'per Month',
    description: 'For small projects or integrations and startups',
    features: [
      'Email Support',
      '1k Daily Requests',
      'HTTP - REST',
      'Websockets | GRPC (Trades)'
    ],
    button: {
      title: 'Get Started',
      to: '/account/register',
      appearance: 'primary',
      state: {
        product: 'data-api',
        package: 'Startup'
      }
    }
  },
  {
    topBorder: theme.color.primary[2],
    topTitle: 'Standard',
    price: '$249',
    priceCondition: 'per Month',
    description: 'For integrations with real-time data stream',
    features: [
      'Email Support',
      '10k Daily Requests',
      'HTTP - REST',
      'Websockets | GRPC (Trades + Quotes)'
    ],
    button: {
      title: 'Get Started',
      to: '/account/register',
      appearance: 'secondary',
      state: {
        product: 'data-api',
        package: 'Standard'
      }
    }
  },
  {
    topBorder: theme.color.primary[3],
    topTitle: 'Professional',
    price: '$599',
    priceCondition: 'per Month',
    description: 'For medium+ size integrations and larger teams',
    features: [
      'Email Support',
      '100k Daily Requests',
      'HTTP - REST',
      'Websockets | GRPC (Unlimited)',
      'FIX Protocol'
    ],
    button: {
      title: 'Get Started',
      to: '/account/register',
      appearance: 'secondary',
      state: {
        product: 'data-api',
        package: 'Professional'
      }
    }
  },
  {
    topBorder: theme.color.primary[4],
    topTitle: 'Enterprise',
    price: 'Custom',
    priceCondition: '',
    description: 'For mission-critical operations or whitelabel solutions',
    features: [
      'Service Level Agreement',
      'Integration Assistance',
      'Premium Support',
      'No Rate Limits',
      'Dedicated Infrastructure',
      'Custom Features',
      'History Data in Flat Files'
    ],
    button: {
      title: 'Contact Us',
      as: 'a',
      href: 'mailto:sales@investabit.com',
      appearance: 'secondary',
      alt: 'Get in touch',
      target: '_blank',
      state: {
        product: 'data-api',
        package: 'Enterprise'
      }
    }
  },
]

export { DataPricing, ApiPricing }
